import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';


const CustomersMap = () => {
    const [customersMapInfo, setCustomersMapInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PageNn, setPageNo] = useState(10);


    const pageLimit = PageNn;

    //filter
    const [CustomerName, setCustomerName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [/* operatorName */, setOperatorName] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    const [operatorID, setoperatorID] = useState("");
    const [/* operator */, setoperator] = useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");
    const [Count, setCount] = useState("");
    const [status, setStatus] = useState("");



    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        //console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        customerMap();
    }, [currentPage, pageLimit])

    useEffect(() => {
        OperatorList();
    }, [])

    const customerMap = () => {
        let request = {
            customer_name: CustomerName,
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
            operator_name: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            active: status,
            rowsPerPage: pageLimit,
            pageNumber: currentPage
        }
        
        API.post('customerMapListfilter/condition',request).then((response) => {
            if (response.data.success == true) {
                setCustomersMapInfo(response.data.data);
                setTotalRecords(response.data.data1[0].count);
                setCount(response.data.data1[0].count);
                setLoading(false);
                /* setPageNo(10);
                setCurrentPage(1); */
            }
        });
    }

    //clear serach list service
    const customerMapClear = () => {
        let request = {
            customer_name: "",
            operator_id: "",
            operator_name: "",
            email: "",
            phone_no: "",
            from_date: "",
            to_date: "",
            active: "",
            rowsPerPage: "10",
            pageNumber: "1"
        }
        
        API.post('customerMapList/condition',request).then((response) => {
            if (response.data.success == true) {
                setCustomersMapInfo(response.data.data);
                setTotalRecords(response.data.data1[0].count);
                setCount(response.data.data1[0].count);
                setLoading(false);
                /* setPageNo(10);
                setCurrentPage(1); */
            }
        });

        API.post('customerMapListfilter/condition',request).then((response) => {
            if (response.data.success == true) {
                setCustomersMapInfo(response.data.data);
                setTotalRecords(response.data.data1[0].count);
                setCount(response.data.data1[0].count);
                setLoading(false);
                /* setPageNo(10);
                setCurrentPage(1); */
            }
        });
    }
    const OperatorList = () => {
        API.post('operatorlist/condition',).then((response) => {
            if (response.data.success == true) {

                setoperatorID(response.data.data);

            }
        });
    }
    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };

    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const handleBuyerId = (e) => {
        setoperator(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
    };

    const customerFilter = () => {
        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }
        setLoading(true)
        let request = {
            customer_name: CustomerName,
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
            operator_name: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            active: status,
            rowsPerPage: pageLimit,
            pageNumber: currentPage
        }

        API.post("customerMapListfilter/condition", request).then(response => {
            setCustomersMapInfo(response.data.data);
            setTotalRecords(response.data.data1[0].count);
            setCount(response.data.data1[0].count);
            setLoading(false)
           /*  document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10); */
        });
    }

    const handleSearch = () => {
        customerFilter();
    }

    const handleclear = () => {

        setOperatorName("");
        setoperatorID("");
        setPhoneNo("");
        setfromdate("");
        setCustomerName("");
        settodate("");
        setStatus("");
        setSelectedoperator("");
        const statusSelect = document.getElementById("status");
        statusSelect.selectedIndex = 0;
        setLoading(true);
        OperatorList();
        customerMapClear();
        //document.getElementById("page").value = "10";
        // document.getElementById("userName").value = "Select userName";
    }
    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        //console.log("mobileno", value);
        return value;
    }

    const redirection = (id) => {

        navigate({ pathname: "/OperatorView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    const redirectioncustomer = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };


    //sorting
   

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...customersMapInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.customermapid - userB.customermapid;
                
            }
                return userB.customermapid - userA.customermapid;
        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });
        
    };

    const sortByCustomerName = () => {
        console.log("sortById start");
        const usersCopy = [...customersMapInfo];
        usersCopy.sort((userA, userB) => {
            console.log("sortById if");
            if (sorted.reversed) {
                return userB.customer_name.localeCompare(userA.customer_name);
            }
            return userA.customer_name.localeCompare(userB.customer_name);
        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "customer_name", reversed: !sorted.reversed });
    };

    const sortByOperatorName = () => {
        const usersCopy = [...customersMapInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.operator_name.localeCompare(userA.operator_name);
            }
            return userA.operator_name.localeCompare(userB.operator_name);
        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "operator_name", reversed: !sorted.reversed });
    };

    const sortByListName = () => {
        console.log("sortById start");
        const usersCopy = [...customersMapInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.list_name || '';
            userB = userB.list_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "list_name", reversed: !sorted.reversed });

    };

    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...customersMapInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.Phone - userB.Phone;
            }
            return userB.Phone - userA.Phone;
        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "Phone", reversed: !sorted.reversed });
    };

    const sortByAssignDate = () => {
        console.log("sortById start");
        const usersCopy = [...customersMapInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.assign_date - userB.assign_date;
            }
            return userB.assign_date - userA.assign_date;
        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "sortByAssignDate", reversed: !sorted.reversed });
    };

    const sortByComments = () => {
        const usersCopy = [...customersMapInfo];
        usersCopy.sort((userA, userB) => {
            userA = userA.comments || '';
            userB = userB.comments || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersMapInfo(usersCopy);
        setSorted({ sorted: "comments", reversed: !sorted.reversed });
    };

   function formatTime(value) {
        let local = ""
       // let offset = ""
        // let utc1 = ""
       // console.log(value, "yyyy1");
       value = value.replace(" ","T")
       value = value+"Z";
       console.log(value, "yyyy1");
        local = new Date(value);
       value =local.toLocaleString();
        //var local = new Date("2023-05-22 08:21:11");
        // offset = local.getTimezoneOffset();
        // value = new Date(local.getTime() - offset * 60000);
        // console.log(value, "yyy");
         value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }



    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">CustomersMap</h5>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="common-heading-sec">

                                {/* <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Search by name or id" />
                            </div>
                            <div className="dropdown-sec me-2">
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}

                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={operatorName} placeholder="Operator Name" onChange={(e) => setOperatorName(e.target.value)} />
                                </div> */}
                                <div className=" col-md-6 col-lg-2 search-bar">
                                    {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handleBuyerId}
                                    >
                                        <option style={{ display: "none" }}>Operator Name</option>
                                        {operatorID?.length > 0 && (
                                            <>
                                                {operatorID.map((operatorID) => (
                                                    <option
                                                        key={operatorID.operator_id}
                                                        value={operatorID.operator_id}
                                                    >
                                                        {operatorID.operatorname}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={CustomerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value)} />
                                </div>
                                <div className="datePickerBlock">
                                    {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>
                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div>

                                <div className="col-lg-2 me-2">
                                        {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                        <select className="form-select" id="status" onChange={(e) => setStatus(e.target.value)}>
                                            <option selected style={{ display: "none" }}  >Status</option>
                                            <option value="1">Active</option>
                                            <option value="4">Inactive</option>
                                        </select>
                                        </div>

                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                            </div>
                        </div>
                        {/* <div className='col-xl-4'>
                            <div className='add-section'>
                                <div className="add-btn" onClick={() => navigate('/customersMapAdd')}><i className="fa-solid fa-plus"></i>Assign Customer</div>
                            </div>
                        </div> */}
                    </div>

                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                    <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                            <span> Count :{Count}</span>
                        </div>
                        </span>
                    </div>
                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByCustomerName}>Customer Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByListName}>List Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPhone}>Phone#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByAssignDate}>Assign Date<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByComments}>Comments<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Assign Admin</th>
                                            <th>Status</th>
                                            <th className='action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {customersMapInfo.length > 0 ? (
                                            customersMapInfo.sort((a, b) => b.customersMapInfo - a.customersMapInfo)
                                                // .slice((currentPage) * pageLimit, currentPage * pageLimit)
                                                .map((customersMapInfo, index) =>
                                                <tr key={index}>
                                                    <td>{customersMapInfo.customermapid}</td>
                                                    {/* <td>{customersMapInfo.customer_name}</td> */}
                                                    <td><a onClick={() => redirectioncustomer(customersMapInfo.customer_id)}>{customersMapInfo.customer_name}</a></td>
                                                    {/* <td>{customersMapInfo.operator_name}</td> */}
                                                    <td><a onClick={() => redirection(customersMapInfo.operator_id)}>{customersMapInfo.operator_name}</a></td>
                                                    <td>{customersMapInfo.list_name}</td>
                                                    <td>{formatMobileNO(customersMapInfo.Phone)}</td>
                                                    <td>{formatTime(customersMapInfo.assigncreatedtimes)}</td>
                                                    <td> {customersMapInfo.comments === null || customersMapInfo.comments === "null" || customersMapInfo.comments === "" ? <td></td> : <td>{customersMapInfo.comments}</td>}</td>
                                                    <td>{customersMapInfo.assign_admin}</td>
                                                    <td>{customersMapInfo.mapactive === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td>

                                                    {customersMapInfo.mapactive === 1 ?
                                                        <td className='action-right-fix-list'><a className="edit" onClick={() => navigate('/customersMapEdit', { state: { CustomerMapId: customersMapInfo.customermap_id } })}>Edit</a></td> :
                                                        <>
                                                            <td className='action-right-fix-list'></td>
                                                        </>
                                                    }

                                                </tr>
                                            )) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}
                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
        </div>
    );
};
export default CustomersMap;